<template>
  <div>
    <b-card>
      <b-form
        style="max-width: 1024px;"
        @submit.prevent
      >
        <b-row class="mt-1 text-left text-md-center align-items-top align-content-start">
          <b-col
            v-if="$store.state.app.isMaster($store)"
            cols="12"
            md="5"
          >
            <b-form-group
              label="Prefix"
              label-for="mc-username"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="prefix"
                v-model="form.prefix"
                placeholder="Prefix"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="$store.state.app.isMaster($store)"
            cols="12"
            md="5"
          />
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="ยูสเซอร์เนม"
              label-for="mc-username"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="mc-username"
                v-model="form.username"
                placeholder="Username"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="เหตุการณ์"
              label-for="mc-type"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <v-select
                v-model="form.type"
                label="title"
                :options="eventTypes"
                placeholder="Event type"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="Round Id"
              label-for="RoundId"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="RoundId"
                v-model="form.roundId"
                placeholder="Round Id"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="Poy Id"
              label-for="PoyId"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="PoyId"
                v-model="form.betId"
                placeholder="Poy Id"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click.prevent="onSubmit"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <!--      <b-row class="justify-content-between">-->
      <!--        <b-col-->
      <!--          cols="2"-->
      <!--          class="mb-sm-1"-->
      <!--        >-->
      <!--          <b-form-group class="text-nowrap mb-0">-->

      <!--            <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>-->
      <!--            <b-form-select-->
      <!--              id="perPageSelect"-->
      <!--              v-model="perPage"-->
      <!--              size="sm"-->
      <!--              :options="pageOptions"-->
      <!--              class="w-50"-->
      <!--              style="min-width: 100px"-->
      <!--            />-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->
      <!--        <b-col-->
      <!--          cols="12"-->
      <!--          lg="4"-->
      <!--          md="6"-->
      <!--          sm="8"-->
      <!--          class="mb-1"-->
      <!--        >-->
      <!--          <b-form-group-->
      <!--            label="Filter"-->
      <!--            label-cols-sm="3"-->
      <!--            label-align-sm="right"-->
      <!--            label-for="filterInput"-->
      <!--            class="mb-0"-->
      <!--          >-->
      <!--            <b-input-group size="sm">-->
      <!--              <b-form-input-->
      <!--                id="filterInput"-->
      <!--                v-model="filterForSearch"-->
      <!--                type="search"-->
      <!--                placeholder="Type prefix or username to search"-->
      <!--                @keydown.enter="filterSearch"-->
      <!--              />-->
      <!--              <b-input-group-append>-->
      <!--                <b-button-->
      <!--                  @click="filterSearch"-->
      <!--                >-->
      <!--                  Search-->
      <!--                </b-button>-->
      <!--              </b-input-group-append>-->
      <!--            </b-input-group>-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->

      <b-table
        ref="table"
        striped
        hover
        responsive
        bordered
        :per-page="perPage"
        :current-page="currentPage"
        :items="reloadData"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy.sync="loading"
        :filter="filter"
      >
        <!--        <template #head(type)="data">-->
        <!--          <span>{{ data.label }}</span>-->
        <!--          <b-badge-->
        <!--            variant="light-primary"-->
        <!--            class="ml-25"-->
        <!--            style="cursor: pointer"-->
        <!--          >-->
        <!--            ?-->
        <!--          </b-badge>-->
        <!--        </template>-->
        <template #cell(username)="v">
          <div>
            <span
              style="text-decoration: underline;cursor: pointer"
              class="font-weight-bolder"
              @click="showUserDetial(v.value)"
            >{{ v.value }}</span><br>@{{ v.item.prefix }}
          </div>
        </template>
        <template #cell(type)="v">
          <span
            class="font-weight-bolder"
          >
            {{ v.value }}
          </span>
          <br>{{ v.item.roundId }}<br>{{ v.item.betId }}
          <!--          <span class="d-flex justify-content-between">-->
          <!--            <span>Type</span>-->
          <!--            <span class="pl-2 font-weight-bolder">  {{ v.value }} </span>-->
          <!--          </span>-->
          <!--          <span class="d-flex justify-content-between">-->
          <!--            <span>Poy</span>-->
          <!--            <span class="pl-2">  {{ v.item.betId }} </span>-->
          <!--          </span>-->
          <!--          <span class="d-flex justify-content-between">-->
          <!--            <span>Round</span>-->
          <!--            <span class="pl-2">  {{ v.item.roundId }} </span>-->
          <!--          </span>-->
        </template>
        <template #cell(request)="v">
          <div
            class="text-effect"
            @click="openRequestModal(v.value)"
          >
            {{ v.value }}
          </div>
        </template>
        <template #cell(response)="v">
          <div
            class="text-effect"
            @click="openResponseModal(v.value)"
          >
            {{ v.value }}
          </div>
        </template>
        <template #cell(edit)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="$router.push(`agents/edit?id=${data.item.username}`)"
          >
            <feather-icon icon="Edit3Icon" />
          </b-button>
        </template>

        <template #cell(delete)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="onDelete(data.item.username)"
          >
            <feather-icon icon="ArchiveIcon" />
          </b-button>
        </template>
      </b-table>
      <b-row>

        <b-col
          cols="2"
        >
          <b-form-group class="text-nowrap mb-0">

            <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
              style="min-width: 100px"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="8"
          md="6"
          sm="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
      <b-modal
        v-model="requestModal"
        cancel-variant="secondary"
        ok-only
        ok-title="Submit"
        centered
        title="Request"
        button-size="sm"
      >
        <json-viewer
          :value="requestData"
          copyable
          sort
          :expand-depth="10"
        />
      </b-modal>

      <b-modal
        v-model="responseModal"
        cancel-variant="secondary"
        ok-only
        ok-title="Submit"
        centered
        title="Response"
        button-size="sm"
      >
        <div>
          <json-viewer
            :value="responseData"
            copyable
            sort
            :expand-depth="10"
          />
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  // BInputGroup,
  BFormInput,
  // BInputGroupAppend,
  BButton, BForm, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import loggingService from '@/service/loggingService'
// import VueJsonPretty from 'vue-json-pretty'
// import 'vue-json-pretty/lib/styles.css'
import JsonViewer from 'vue-json-viewer'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BForm,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    vSelect,
    // VueJsonPretty,
    JsonViewer,
  },
  data() {
    return {
      form: {
        prefix: '',
        username: '',
        roundId: '',
        betId: '',
        type: '',
      },
      eventTypes: ['ทั้งหมด', 'BET', 'PAYOUT', 'CANCEL', 'CANCEL_NUMBER', 'VOID'],
      requestModal: false,
      requestData: '',
      responseModal: false,
      responseData: '',
      loading: false,
      perPage: 25,
      pageOptions: [1, 5, 10, 25, 50, 100, 150, 250, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'createAt',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterForSearch: '',
      // filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'index',
          label: 'ลำดับ',
        },
        {
          key: 'createAt',
          label: 'Created',
          class: 'text-wrap',
        },
        // {
        //   key: 'agent',
        //   label: 'agent',
        // },
        // {
        //   key: 'roundId',
        //   label: 'round',
        // },
        // {
        //   key: 'betId',
        //   label: 'poyId',
        // },
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'type',
          label: 'event',
        },
        {
          key: 'statusCode',
          label: 'status',
          class: 'text-center',
        },
        {
          key: 'request',
          label: 'request',
          class: 'h-100',
          thStyle: {
            'min-width': '1000px',
          },
        },
        {
          key: 'response',
          label: 'response',
          class: 'h-100',
          thStyle: {
            'min-width': '1000px',
          },
        },
        {
          key: 'refId',
          label: 'refId',
          class: 'text-center text-nowrap',
        },
      ],
    }
  },
  methods: {
    onSubmit() {
      const {
        prefix, username, type, roundId, betId,
      } = this.form

      this.filter = ''
      if (prefix !== '') {
        this.filter += `&prefix=${prefix}`
      }

      if (username !== '') {
        this.filter += `&username=${username}`
      }

      if (type !== '' && type !== 'ทั้งหมด') {
        this.filter += `&type=${type}`
      }

      if (roundId !== '') {
        this.filter += `&round=${roundId}`
      }

      if (betId !== '') {
        this.filter += `&poy=${betId}`
      }
    },
    showUserDetial(username) {
      this.$router.push({ name: 'members-edit', query: { id: username } })
    },
    openRequestModal(data) {
      this.requestData = JSON.parse(data)
      this.requestModal = true
    },
    openResponseModal(data) {
      this.responseData = JSON.parse(data)
      this.responseModal = true
    },
    filterSearch() {
      this.filter = this.filterForSearch
    },
    async reloadData() {
      const startRow = (this.currentPage - 1) * this.perPage
      if (this.filter === '') {
        return []
      }
      this.loading = true
      const res = await loggingService.getAuthen(`req-res?${this.filter}&limit=${this.perPage}&page=${startRow}&sortBy=${this.sortBy}&desc=${this.sortDesc}`)
      if (res.status === 200) {
        this.totalRows = res.data.count
        this.loading = false
        const { list } = res.data
        if (this.sortDesc === false) {
          let index = startRow + 1
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index += 1
          }
        } else {
          let index = res.data.count
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index -= 1
          }
        }
        return list
      }
      this.loading = false
      return []
    },
    onDelete(username) {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการลบข้อมูลใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const res = await loggingService.deleteAuthen(`?username=${username}`)
            if (res.status === 200) {
              this.$refs.table.refresh()

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

table {
  .sr-only {
    width: 0px !important;
  }

  .dropdown-menu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.btn-group {
  .btn {
    padding: 5px;
  }
}

.text-effect:hover {
  font-weight: bolder;
}

.h-100[role="columnheader"] {
  height: auto !important;
}
.h-100 {
  height: 100px !important;
}
</style>
